import Link from 'next/link'
import type { ServiceTileResult } from '../app/data/content/ServiceTileFragment'
import styles from '../styles/ServiceTile.module.sass'
import { Arrow } from './Arrow'
import { ContemberImage } from './ContemberImage'

export const ServiceTile = (props: { serviceTile: ServiceTileResult }) => {
	const { serviceTile } = props

	return (
		<div className={styles.wrapper}>
			<Link href={serviceTile.link?.url || '/'}>
				<a>
					<div className={styles.tileImage}>
						{serviceTile.tileImage && (
							<ContemberImage image={serviceTile.tileImage} layout="fill" objectFit="cover" />
						)}
					</div>
					<div className={styles.tileTitle}>
						{serviceTile.tileTitle}
						<div className={styles.arrow}>
							<Arrow />
						</div>
					</div>
					<div className={styles.hoveredDiv}>
						<div className={styles.tileText}>{serviceTile.tileText}</div>
						<div className={styles.tileArrow}>
							<Arrow />
						</div>
					</div>
				</a>
			</Link>
		</div>
	)
}
