import clsx from 'clsx'
import styles from '../styles/ArrowLink.module.sass'

export const ArrowLink = (props: { title: string; serviceTitle?: boolean; color?: string }) => {
	const { title, serviceTitle, color } = props

	const words = title.split(' ')

	return (
		<div
			className={styles.serviceTitle}
			style={{ '--arrowLinkTitle-color': `${color}` } as React.CSSProperties}>
			{words.map((word, i) => {
				if (i + 1 === words.length) {
					return (
						<span key={i} className={styles.serviceTitleWrapper}>
							{word}{' '}
							<div className={clsx(styles.arrow, serviceTitle && styles.serviceArrow)}>
								<svg
									width="18"
									height="15"
									viewBox="0 0 50 42"
									fill="none"
									xmlns="http://www.w3.org/2000/svg">
									<path
										d="M27.9393 37.9393L26.8787 39L29 41.1213L30.0607 40.0607L27.9393 37.9393ZM47 21L48.0607 22.0607L49.1213 21L48.0607 19.9393L47 21ZM30.0607 1.93934L29 0.878679L26.8787 3L27.9393 4.06066L30.0607 1.93934ZM2 19.5L0.500002 19.5L0.500002 22.5L2 22.5L2 19.5ZM30.0607 40.0607L48.0607 22.0607L45.9393 19.9393L27.9393 37.9393L30.0607 40.0607ZM48.0607 19.9393L30.0607 1.93934L27.9393 4.06066L45.9393 22.0607L48.0607 19.9393ZM47 19.5L2 19.5L2 22.5L47 22.5L47 19.5Z"
										fill="currentColor"
									/>
								</svg>
							</div>
						</span>
					)
				}
				return word + ' '
			})}
		</div>
	)
}
