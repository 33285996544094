import { RichTextRenderer } from '@contember/react-client'
import Link from 'next/link'
import type { SiteResult } from '../app/data/content/SiteFragment'
import styles from '../styles/Footer.module.sass'
import { ArrowLink } from './ArrowLink'
import { ContemberImage } from './ContemberImage'
import { ContemberLink } from './ContemberLink'

export const Footer = (props: { footer: SiteResult['getFooter'] }) => {
	const { logo, email, tel, address, navigation, creator, IC, termsAndConditions } =
		props.footer?.localesByLocale ?? {}
	return (
		<div className={styles.wrapper}>
			<div className={styles.content}>
				<Link href="/">
					<a>
						<div className={styles.logoWrapper}>
							<div className={styles.logo}>
								{logo && <ContemberImage image={logo} layout="fill" objectFit="contain" />}
							</div>
						</div>
					</a>
				</Link>
				<div className={styles.infoWrapper}>
					<div>
						<div className={styles.email}>
							<a href={`mailto:${email}`}>{email}</a>
						</div>
						<div className={styles.tel}>
							<a href={`tel:${tel}`}>{tel}</a>
						</div>
						<div className={styles.IC}>{IC}</div>
					</div>
					<div className={styles.address}>{address && <RichTextRenderer source={address} />}</div>
				</div>
				<div className={styles.labelList}>
					{navigation?.items.map((item) => {
						return (
							<div key={item.id}>
								{item.link?.title && (
									<div className={styles.label}>
										<Link href={item.link.externalLink || item.link.internalLink?.url || '/'}>
											<a>
												<ArrowLink title={item.link.title} />
											</a>
										</Link>
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
			<div className={styles.additionalInfo}>
				<div className={styles.termsAndConditions}>
					<ContemberLink link={termsAndConditions} />
				</div>
				<div className={styles.creator}>{creator && <RichTextRenderer source={creator} />}</div>
			</div>
		</div>
	)
}
