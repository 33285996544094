import type { RealizationTileResult } from '../app/data/content/RealizationTileFragment'
import styles from '../styles/RealizationTilesList.module.sass'
import { RealizationTile } from './RealizationTile'

export const RealizationTilesList = (props: { realizationTilesList: RealizationTileResult[] }) => {
	const { realizationTilesList } = props

	return (
		<>
			<div className={styles.tilesList}>
				{realizationTilesList.map((realizationItem) => {
					return (
						<div key={realizationItem.id} className={styles.tile}>
							{realizationItem && <RealizationTile realizationTile={realizationItem} />}
						</div>
					)
				})}
			</div>
		</>
	)
}
