/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import Link from 'next/link'
import { useState } from 'react'
import { useLockBodyScroll } from 'react-use'
import type { SiteResult } from '../app/data/content/SiteFragment'
import styles from '../styles/Header.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { LocaleSwitcher } from './LocaleSwitcher'
import { Navigation } from './Navigation'

type HeaderProps = {
	header: SiteResult['getHeader']
	services: SiteResult['listService']
	activeLocaleCode?: string
	pageLocales?: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	invertedHeader?: boolean
	isDetailPageHeader?: boolean
}
export const Header = (props: HeaderProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const { header, services, pageLocales, activeLocaleCode, invertedHeader, isDetailPageHeader } =
		props

	useLockBodyScroll(isOpen)

	return (
		<div className={clsx(styles.wrapper, invertedHeader && styles.invertedHeader)}>
			<div className={clsx(styles.navigation, isOpen && styles.isOpen)}>
				<div className={styles.navigationContent}>
					<Navigation
						onClose={() => setIsOpen(false)}
						navigation={header}
						services={services}
						pageLocales={pageLocales}
						activeLocaleCode={activeLocaleCode}
					/>
				</div>
				<div
					className={styles.background}
					role="button"
					onClick={() => setIsOpen(false)}
					onKeyPress={(event) => {
						if (event.code === 'esc') {
							setIsOpen(false)
						}
					}}></div>
			</div>
			<Container>
				<div
					className={clsx(
						styles.content,
						invertedHeader && styles.invertedContent,
						isDetailPageHeader && styles.detailPageContent
					)}>
					<div className={clsx(isDetailPageHeader && styles.detailPageLogo)}>
						<Link href="/">
							<a>
								{header?.localesByLocale?.logo && !invertedHeader && (
									<div className={styles.logo}>
										<ContemberImage
											image={header?.localesByLocale?.logo}
											layout="fill"
											objectFit="contain"
										/>
									</div>
								)}
							</a>
						</Link>
					</div>

					<div className={styles.headerButtons}>
						<div className={styles.locales}>
							<LocaleSwitcher
								pageLocales={pageLocales}
								activeLocaleCode={activeLocaleCode}
								invertedHeader={invertedHeader}
							/>
						</div>
						<button
							className={clsx(styles.button, invertedHeader && styles.invertedButton)}
							onClick={() => setIsOpen(!isOpen)}>
							<div className={styles.linesWrapper}>
								<div className={clsx(styles.line, invertedHeader && styles.invertedLine)}></div>
								<div className={clsx(styles.line, invertedHeader && styles.invertedLine)}></div>
								<div className={clsx(styles.line, invertedHeader && styles.invertedLine)}></div>
							</div>
							Menu
						</button>
					</div>
				</div>
			</Container>
		</div>
	)
}
