import Link from 'next/link'
import type React from 'react'
import type { ButtonHTMLAttributes } from 'react'
import type { LinkResult } from '../app/data/content/LinkFragment'
import styles from '../styles/Button.module.sass'
import { ContemberLink } from './ContemberLink'

type ButtonProps = {
	link?: LinkResult
	linkUrl?: string
	title?: string
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
	color?: string
}

export const Button = (props: ButtonProps) => {
	const { link, linkUrl, title, type, color } = props
	return (
		<button
			className={styles.wrapper}
			type={type}
			style={{ '--buttonLink-color': `${color}` } as React.CSSProperties}>
			{link && <ContemberLink link={link} />}
			{linkUrl && (
				<Link href={linkUrl}>
					<a>
						<div>{title}</div>
					</a>
				</Link>
			)}
			{!linkUrl && !link && title && <div>{title}</div>}
			<div className={styles.arrow}>
				<svg
					width="36"
					height="31"
					viewBox="0 0 36 31"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M19.5274 27.3171L18.4788 28.3896L20.6239 30.4869L21.6726 29.4144L19.5274 27.3171ZM33 15.6829L34.0726 16.7315L35.0978 15.6829L34.0726 14.6342L33 15.6829ZM21.6726 1.95137L20.6239 0.87882L18.4788 2.97609L19.5274 4.04864L21.6726 1.95137ZM2 14.1829L0.499999 14.1829L0.499999 17.1829L2 17.1829L2 14.1829ZM21.6726 29.4144L34.0726 16.7315L31.9274 14.6342L19.5274 27.3171L21.6726 29.4144ZM34.0726 14.6342L21.6726 1.95137L19.5274 4.04864L31.9274 16.7315L34.0726 14.6342ZM33 14.1829L2 14.1829L2 17.1829L33 17.1829L33 14.1829Z"
						fill="currentColor"
					/>
				</svg>
			</div>
		</button>
	)
}
