import { ReferenceRendererProps, RichTextRenderer } from '@contember/react-client'
import type {
	ContentBlockReference,
	ContentBlockResult,
} from '../app/data/content/ConetntBlockFragment'
import { DescriptionsSection } from './DescriptionsSection'

type ContentBlocksProps = {
	blocks?: ContentBlockResult[]
}

export const ContentBlocks = ({ blocks }: ContentBlocksProps) => {
	return (
		<div>
			<RichTextRenderer
				blocks={blocks ?? []}
				sourceField="json"
				referenceRenderers={{
					features: function Features(block: ReferenceRendererProps<ContentBlockReference>) {
						return (
							<>
								{block.reference.featuresList && block.reference.imageList && (
									<DescriptionsSection
										title={block.reference.primaryText}
										descriptions={block.reference.featuresList}
										images={block.reference.imageList}
									/>
								)}
							</>
						)
					},
				}}
			/>
		</div>
	)
}
