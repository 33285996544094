import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Button } from '../../../components/Button'
import { FlashMessage } from '../../../components/FlashMessage'
import { Input } from '../../../components/Input'
import { createFetcher } from '../../../packages/@necktip/next/api/jsonApiHandler'
import type { API } from '../../../pages/api/v1/[handler]'
import styles from '../../../styles/ContactForm.module.sass'
import type { ContactFormResult } from '../../data/content/ContactFormFragment'
import { validateZod } from '../../validators/utils/validateZod'
import { ContactFormInput } from './ContactFormInput'

type ContactFormProps = {
	contactForm: ContactFormResult
}

export const api = createFetcher<API>('/api/v1/[handler]')

export const ContactForm = (props: ContactFormProps) => {
	const mutation = useMutation(async (formData: FormData) => {
		const data = Object.fromEntries(formData.entries())

		const validatedData = validateZod(data, ContactFormInput())

		return api('contactForm', validatedData)
	})
	const { contactForm } = props

	const [showFlashMessage, setShowFlashMessage] = useState(false)

	useEffect(() => {
		setShowFlashMessage(mutation.isSuccess)
	}, [mutation.isSuccess])

	return (
		<>
			{showFlashMessage && <FlashMessage hideFlashMessage={() => setShowFlashMessage(false)} />}
			<div className={styles.wrapper}>
				<div className={styles.content}>
					<div className={styles.title}>{contactForm.title}</div>
					<form
						className={styles.inputsWrapper}
						onSubmit={(e) => {
							e.preventDefault()
							mutation.mutate(new FormData(e.currentTarget))
						}}>
						<Input
							name="name"
							type="text"
							placeholder={contactForm.name}
							required
							autoComplete="name"
						/>
						<Input
							name="tel"
							type="tel"
							placeholder={contactForm.tel}
							required
							autoComplete="tel"
						/>
						<Input
							name="email"
							type="email"
							placeholder={contactForm.email}
							required
							autoComplete="email"
						/>
						<textarea name="note" placeholder={contactForm.note} className={styles.note} />
						<div className={styles.submitButton}>
							<Button title={contactForm.submitButton} type="submit" />
						</div>
					</form>
				</div>
			</div>
		</>
	)
}
