import clsx from 'clsx'
import Link from 'next/link'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import styles from '../styles/ArticleTile.module.sass'
import { Arrow } from './Arrow'
import { ArrowLink } from './ArrowLink'
import { ContemberImage } from './ContemberImage'

export const ArticleTile = (props: {
	articleTile: ArticleTileResult
	showImage: boolean
	view?: string
}) => {
	const { articleTile, showImage = true } = props
	const date = new Date(articleTile?.publishDate)
	return (
		<div className={clsx(styles.wrapper, props.view && styles['view-' + props.view])}>
			<Link href={articleTile.localesByLocale?.link?.url || '/'}>
				<a>
					<div className={styles.tileImageWrapper}>
						{articleTile.localesByLocale?.media?.items[0].image && showImage && (
							<div className={styles.tileImage}>
								<ContemberImage
									image={articleTile.localesByLocale?.media?.items[0].image}
									layout="fill"
									objectFit="cover"
								/>
								<div className={styles.arrowWrapper}>
									<div className={styles.arrow}>
										<Arrow />
									</div>
								</div>
							</div>
						)}
						{articleTile.localesByLocale?.media?.items[0].video?.poster && showImage && (
							<div className={styles.tileImage}>
								<ContemberImage
									image={articleTile.localesByLocale?.media?.items[0].video?.poster}
									layout="fill"
									objectFit="cover"
								/>
								<div className={styles.arrowWrapper}>
									<div className={styles.arrow}>
										<Arrow />
									</div>
								</div>
							</div>
						)}
					</div>
					<div className={styles.date}>
						{`${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`}
					</div>
					{articleTile.localesByLocale?.title && (
						<div className={styles.articleText}>
							<ArrowLink title={articleTile.localesByLocale?.title} />
						</div>
					)}
				</a>
			</Link>
		</div>
	)
}
