/* eslint-disable jsx-a11y/media-has-caption */

import type { MediaListResult } from '../app/data/content/MediaListFragment'
import styles from '../styles/MediaList.module.sass'
import { ContemberImage } from './ContemberImage'

export const MediaList = (props: { mediaList: MediaListResult }) => {
	const { mediaList } = props

	return (
		<div className={styles.wrapper}>
			{mediaList.items.map((medium) => {
				return (
					<div key={medium.id}>
						{medium.image && (
							<div className={styles.imageWrapper}>
								<div className={styles.image}>
									<ContemberImage image={medium.image} layout="fill" objectFit="cover" />
								</div>
								<div className={styles.imageDescription}>{medium.description}</div>
							</div>
						)}
						<>
							{medium.video?.src && !medium.video?.youtube?.videoId && (
								<div className={styles.videoWrapper}>
									<video poster={medium.video?.poster?.url} className={styles.video} controls>
										<source src={medium.video?.src} type={medium.video?.type} />
										Your browser does not support the video tag.
									</video>
								</div>
							)}
							{medium.video?.youtube?.videoId && (
								<div className={styles.ytVideo}>
									<iframe
										className={styles.iframe}
										title="youtube video"
										src={`https://www.youtube.com/embed/${medium.video?.youtube?.videoId}?autoplay=0`}
										allowFullScreen
										frameBorder="0"></iframe>
								</div>
							)}
						</>
					</div>
				)
			})}
		</div>
	)
}
