import Image from 'next/image'
import type { BannerResult } from '../app/data/content/BannerFragment'
import arrow from '../public/arrowWhite.svg'
import styles from '../styles/Banner.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContemberLink } from './ContemberLink'

export const Banner = (props: { banner: BannerResult }) => {
	const { banner } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				{banner.image && <ContemberImage image={banner.image} layout="fill" objectFit="cover" />}
			</div>
			<div className={styles.mobileImage}>
				{banner.mobileImage && (
					<ContemberImage image={banner.mobileImage} layout="fill" objectFit="cover" />
				)}
			</div>
			<div className={styles.content}>
				<Container>
					<div className={styles.titleWrapper}>
						<div className={styles.title}>{banner.title}</div>
					</div>
					<div className={styles.button}>
						<ContemberLink link={banner.buttonLink}>{banner.buttonLink?.title}</ContemberLink>
						<div className={styles.arrow}>
							<Image src={arrow} alt="" />
						</div>
					</div>
				</Container>
			</div>
		</div>
	)
}
