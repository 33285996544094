import styles from '../styles/MemberModal.module.sass'
import { Container } from './Container'

export const MemberModal = (props: { name: string; text: string; onClose: () => void }) => {
	const { name, text, onClose } = props
	return (
		<Container contentWidth="narrow">
			<div className={styles.wrapper}>
				<button className={styles.button} onClick={() => onClose()}>
					<div className={styles.buttonWrapper}>
						<div className={styles.line1}></div>
						<div className={styles.line2}></div>
					</div>
				</button>
				<div className={styles.name}>{name}</div>
				<div className={styles.text}>{text}</div>
			</div>
		</Container>
	)
}
