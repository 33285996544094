import clsx from 'clsx'
import Link from 'next/link'
import styles from '../styles/LocaleSwitcher.module.sass'

type LocaleSwitcherProps = {
	activeLocaleCode?: string
	pageLocales?: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	invertedHeader?: boolean
}

export const LocaleSwitcher = (props: LocaleSwitcherProps) => {
	const { activeLocaleCode, pageLocales, invertedHeader } = props
	return (
		<div className={styles.wrapper}>
			{pageLocales?.map((pageLocale) => {
				return (
					<div
						key={pageLocale.id}
						className={clsx(
							styles.locale,
							pageLocale.locale?.code === activeLocaleCode && styles.activeLocaleCode,
							invertedHeader && styles.invertedLocale
						)}>
						<Link href={pageLocale.link?.url || '/'} locale={pageLocale.locale?.code}>
							<a>{pageLocale.locale?.code}</a>
						</Link>
					</div>
				)
			})}
		</div>
	)
}
