/* eslint-disable jsx-a11y/media-has-caption */
import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import Head from 'next/head'
import type React from 'react'
import { useTranslate } from '../../app/hooks/useTranslate'
import { Button } from '../../components/Button'
import { Container } from '../../components/Container'
import { ContemberImage } from '../../components/ContemberImage'
import { ContentBlocks } from '../../components/ContentBlocks'
import { DescriptionsSection } from '../../components/DescriptionsSection'
import { ImageCarousel } from '../../components/ImageCarousel'
import { RealizationTilesList } from '../../components/RealizationTilesList'
import styles from '../../styles/ServiceDetailPage.module.sass'
import type { ServiceLocaleResult } from '../data/content/ServiceLocaleFragment'
import { filterNonEmpty } from '../utils/filterNonEmpty'

export function ServiceDetailPage(props: {
	serviceDetailPage: ServiceLocaleResult
	extraProps?: { getRealizationsPage?: { localesByLocale?: { link?: { url: string } } } } | null
}) {
	const { serviceDetailPage, extraProps } = props
	const translate = useTranslate()

	return (
		<div
			style={
				serviceDetailPage.root?.isSpecialServiceType
					? ({
							'--serviceType-color': ' #261E52',
					  } as React.CSSProperties)
					: undefined
			}>
			<Head>
				<title>{serviceDetailPage.title}</title>
				<meta name="description" content={serviceDetailPage.seo?.description} />
				<meta property="og:title" content={serviceDetailPage.seo?.ogTitle} />
				<meta property="og:description" content={serviceDetailPage.seo?.ogDescription} />
				<meta property="og:image" content={serviceDetailPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				{serviceDetailPage.backgroundImg && (
					<div className={styles.backgroundImg}>
						<ContemberImage image={serviceDetailPage.backgroundImg} />
					</div>
				)}
				{serviceDetailPage.backgroundImgMobile && (
					<div className={styles.mobileImage}>
						<div className={styles.backgroundImgMobileWrapper}>
							<div
								className={styles.backgroundImgMobile}
								style={
									{
										'--serviceBackgroundImg-aspectRatio': `${
											(serviceDetailPage.backgroundImgMobile.width ?? 282) /
											(serviceDetailPage.backgroundImgMobile.height ?? 410)
										}`,
									} as React.CSSProperties
								}>
								<ContemberImage
									image={serviceDetailPage.backgroundImgMobile}
									layout="fill"
									objectFit="cover"
								/>
							</div>
						</div>
					</div>
				)}
				<div
					className={clsx(
						styles.title,
						serviceDetailPage.root?.isSpecialServiceType && styles.isSpecialServiceType
					)}>
					{serviceDetailPage.title}
					{serviceDetailPage.titleIcon && (
						<div className={styles.titleIconWrapper}>
							<div className={styles.titleIcon}>
								<ContemberImage
									image={serviceDetailPage.titleIcon}
									layout="fill"
									objectFit="contain"
								/>
							</div>
						</div>
					)}
				</div>
				<div className={styles.mainSection}>
					<div className={styles.text}>
						{serviceDetailPage.text && <RichTextRenderer source={serviceDetailPage.text} />}
					</div>
					<div className={styles.imageCarousel}>
						{serviceDetailPage.imageList && <ImageCarousel images={serviceDetailPage.imageList} />}
					</div>
				</div>
				{serviceDetailPage.descriptions && (
					<DescriptionsSection
						title={serviceDetailPage.subTitle}
						descriptions={serviceDetailPage.descriptions}
						images={serviceDetailPage.descriptionsImages}
					/>
				)}
				<div>
					<ContentBlocks blocks={serviceDetailPage.content?.blocks} />
				</div>
				<div className={styles.media}>
					{serviceDetailPage.video?.youtube?.videoId && (
						<div className={styles.ytVideo}>
							<iframe
								className={styles.iframe}
								src={`https://www.youtube.com/embed/${serviceDetailPage.video.youtube.videoId}?autoplay=0`}
								title="youtube video"
								allowFullScreen
								frameBorder="0"></iframe>
						</div>
					)}
					{serviceDetailPage.video && !serviceDetailPage.video.youtube?.videoId && (
						<>
							<div className={styles.videoWrapper}>
								<video
									className={styles.video}
									poster={serviceDetailPage.video.poster?.url}
									controls
									autoPlay
									playsInline>
									<source src={serviceDetailPage.video.src} type={serviceDetailPage.video.type} />
									Your browser does not support the video tag.
								</video>
							</div>
							<div className={styles.videoTitle}>{serviceDetailPage.videoTitle}</div>
						</>
					)}
				</div>
				{serviceDetailPage.root?.realizations &&
					(serviceDetailPage.root?.realizations?.length ?? 0) > 0 && (
						<div>
							<div className={styles.realizationsTitle}>
								{translate('recommendedRealizations.title')}
							</div>
							<div className={styles.recommendedRealizations}>
								<RealizationTilesList
									realizationTilesList={serviceDetailPage.root.realizations
										.map((realizationTile) => realizationTile.localesByLocale)
										.filter(filterNonEmpty)}
								/>
							</div>
							<div className={styles.buttonWrapper}>
								<div
									className={clsx(
										styles.button,
										serviceDetailPage.root.realizations.length === 1 && styles.oneItem,
										serviceDetailPage.root.realizations.length === 2 && styles.twoItems,
										serviceDetailPage.root.realizations.length === 3 && styles.threeItems
									)}>
									<Button
										title={translate('recommendedRealizations.button')}
										linkUrl={extraProps?.getRealizationsPage?.localesByLocale?.link?.url}
									/>
								</div>
							</div>
						</div>
					)}
			</Container>
		</div>
	)
}
