import type { InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import Script from 'next/script'
import { TranslationsContextProvider } from '../app/contexts/TranslationsContext'
import { combineUrl } from '../app/data/combineUrl'
import { ArticleLocaleFragment } from '../app/data/content/ArticleLocaleFragment'
import { ArticlesPageLocaleFragment } from '../app/data/content/ArticlesPageLocaleFragment'
import { ArticleTileFragment } from '../app/data/content/ArticleTileFragment'
import { ContactPageLocaleFragment } from '../app/data/content/ContactPageLocaleFragment'
import { HomePageLocaleFragment } from '../app/data/content/HomePageLocaleFragment'
import { RealizationLocaleFragment } from '../app/data/content/RealizationLocaleFragment'
import { RealizationsPageLocaleFragment } from '../app/data/content/RealizationsPageLocaleFragment'
import { RealizationTileFragment } from '../app/data/content/RealizationTileFragment'
import { ServiceLocaleFragment } from '../app/data/content/ServiceLocaleFragment'
import { SiteFragment } from '../app/data/content/SiteFragment'
import { ArticleDetailpage } from '../app/pages/ArticleDetailPage'
import { ArticlesPage } from '../app/pages/ArticlesPage'
import { ContactPage } from '../app/pages/ContactPage'
import { Error404Page } from '../app/pages/Error404PAge'
import { HomePage } from '../app/pages/HomePage'
import { RealiazationDetailPage } from '../app/pages/RealizationDetailPage'
import { RealizationsPage } from '../app/pages/RealizationsPage'
import { ServiceDetailPage } from '../app/pages/ServiceDetailPage'
import { Container } from '../components/Container'
import { CookieBar } from '../components/CookieBar'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { Idea } from '../components/Idea'
import { RecommededArticles } from '../components/RecommendedArticles'
import { One, OrderDirection, Thunder } from '../generated/content'
import { serverPropsHandler } from '../packages/@necktip/next/api/handlers'
import { getZeusConnection } from '../packages/@necktip/next/loaders/graphql'

export type CommonPageProps = InferGetServerSidePropsType<typeof getServerSideProps>

export default function CommonPage(props: CommonPageProps) {
	const invertedHeader = Boolean(props.homePage)
	const isDetailPageHeader = Boolean(props.article || props.realization)

	const page =
		props.homePage ||
		props.contactPage ||
		props.realization ||
		props.realizationsPage ||
		props.articlesPage ||
		props.article ||
		props.service

	return (
		<div className="layout">
			<TranslationsContextProvider data={props.dataFromContember.getTranslationDomain}>
				<Head>
					<title>Next Boilerplate example</title>
					<link rel="icon" href="/favicon.ico" />
				</Head>
				<Script
					id="ifirmy-script"
					strategy="afterInteractive"
					dangerouslySetInnerHTML={{
						__html: `
						var _paq = _paq || [];
						_paq.push(['trackPageView']);
						_paq.push(['enableLinkTracking']);
						(function() {
							var u="//ifirmy.cz/pxstats/";
							_paq.push(['setTrackerUrl', u+'piwik.php']);
							_paq.push(['setSiteId',7380]);
							var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
							g.type='text/javascript'; g.async=true; g.defer=true; g.src=u+'piwik.js'; s.parentNode.insertBefore(g,s);
						})();
						`,
					}}
				/>
				<noscript>
					<p>
						{/* eslint-disable-next-line @next/next/no-img-element */}
						<img src="//ifirmy.cz/pxstats/piwik.php?idsite=7380" style={{ border: 0 }} alt="" />
					</p>
				</noscript>
				<header className="layout-header">
					<Header
						header={props.dataFromContember.getHeader}
						services={props.dataFromContember.listService}
						activeLocaleCode={props.urlInfo.locale}
						pageLocales={page?.root?.locales}
						invertedHeader={invertedHeader}
						isDetailPageHeader={isDetailPageHeader}
					/>
				</header>
				<main className="layout-main">
					{props.dataFromContember.getLinkable?.homePage && (
						<HomePage
							homePage={props.dataFromContember.getLinkable?.homePage}
							articles={props.articlesList?.listArticle}
						/>
					)}

					{props.dataFromContember.getLinkable?.contactPage && (
						<ContactPage contactPage={props.dataFromContember.getLinkable?.contactPage} />
					)}

					{props.dataFromContember.getLinkable?.realization && (
						<RealiazationDetailPage
							realizationDetailPage={props.dataFromContember.getLinkable?.realization}
							extraProps={props.allRealiazationsButtonDetailPage}
						/>
					)}
					{props.dataFromContember.getLinkable?.realizationsPage && (
						<RealizationsPage
							realizationsPage={props.dataFromContember.getLinkable?.realizationsPage}
							realizations={props.realizationsPageExtras?.listRealizationLocale}
						/>
					)}
					{props.dataFromContember.getLinkable?.article && (
						<ArticleDetailpage
							articleDetailPage={props.dataFromContember.getLinkable?.article}
							extraProps={props.allArticlesButtonDetailPage}
						/>
					)}
					{props.dataFromContember.getLinkable?.articlesPage && (
						<ArticlesPage
							articlesPage={props.dataFromContember.getLinkable?.articlesPage}
							articles={props.articlesPageExtras?.listArticle}
						/>
					)}
					{props.dataFromContember.getLinkable?.service && (
						<ServiceDetailPage
							serviceDetailPage={props.dataFromContember.getLinkable?.service}
							extraProps={props.allRealiazationsButtonDetailPage}
						/>
					)}
					{props.recommendedArticles && (
						<RecommededArticles
							recommendedArticles={props.recommendedArticles.listArticle}
							extraProps={props.allArticlesButtonDetailPage}
						/>
					)}

					{(props.homePage || props.service) && (
						<Container contentWidth="narrow">
							<Idea
								idea={props.dataFromContember.getIdea}
								isSpecialServiceType={
									props.dataFromContember.getLinkable?.service?.root?.isSpecialServiceType
								}
							/>
						</Container>
					)}
					{props.is404 && <Error404Page error404Page={props.dataFromContember.getError404Page} />}
				</main>
				<footer className="layout-footer">
					<Container>
						<Footer footer={props.dataFromContember.getFooter} />
					</Container>
				</footer>
				{!props.is404 && <CookieBar cookieBar={props.dataFromContember.getCookieBar} />}
			</TranslationsContextProvider>
		</div>
	)
}

export const getServerSideProps = serverPropsHandler(async (context) => {
	const content = getZeusConnection(Thunder, 'content')

	const urlInfo = combineUrl(context)

	const url = `${urlInfo.path}`

	const dataFromContember = await content.query({
		...SiteFragment(urlInfo.locale),
		getLinkable: [
			{ by: { url } },
			{
				id: true,
				url: true,

				homePage: [{}, HomePageLocaleFragment(urlInfo.locale)],
				realizationsPage: [{}, RealizationsPageLocaleFragment()],
				articlesPage: [{}, ArticlesPageLocaleFragment()],
				realization: [{}, RealizationLocaleFragment()],
				contactPage: [{}, ContactPageLocaleFragment()],
				article: [{}, ArticleLocaleFragment()],
				service: [{}, ServiceLocaleFragment(urlInfo.locale)],
			},
		],

		getTranslationDomain: [
			{ by: { identifier: 'app' } },
			{
				cataloguesByIdentifier: [
					{ by: { identifier: urlInfo.locale } },
					{
						values: [{}, { key: [{}, { identifier: true }], value: true }],
						fallback: [
							{},
							{
								values: [{}, { key: [{}, { identifier: true }], value: true }],
							},
						],
					},
				],
			},
		],
	})

	const activeUrl = dataFromContember.getLinkable?.url
	const is404 = !activeUrl

	if (!dataFromContember.getLinkable) {
		context.res.statusCode = 404
	}

	const { homePage, realizationsPage, articlesPage, contactPage } =
		dataFromContember.getLinkable ?? {}
	const { realization, article, service } = dataFromContember.getLinkable ?? {}

	const recommendedArticles =
		service &&
		(await content.query({
			listArticle: [
				{
					limit: 6,
					orderBy: [{ publishDate: OrderDirection.desc }],
					filter: {
						and: [
							{
								categories: { id: { in: service.root?.categories.map((category) => category.id) } },
							},
							{ publishDate: { lte: 'now' } },
						],
					},
				},

				ArticleTileFragment(urlInfo.locale),
			],
		}))

	const realizationsPageExtras =
		realizationsPage &&
		(await content.query({
			listRealizationLocale: [
				{
					orderBy: [{ root: { order: OrderDirection.asc } }],
					filter: { locale: { code: { eq: urlInfo.locale } } },
				},
				RealizationTileFragment(),
			],
		}))

	const articlesPageExtras =
		articlesPage &&
		(await content.query({
			listArticle: [
				{
					orderBy: [{ publishDate: OrderDirection.desc }],
					filter: {
						publishDate: { lte: 'now' },
					},
				},
				ArticleTileFragment(urlInfo.locale),
			],
		}))

	const allRealiazationsButtonDetailPage =
		(realization || service) &&
		(await content.query({
			getRealizationsPage: [
				{ by: { unique: One.One } },
				{
					localesByLocale: [
						{ by: { locale: { code: urlInfo.locale } } },
						{
							link: [
								{},
								{
									url: true,
								},
							],
						},
					],
				},
			],
		}))

	const allArticlesButtonDetailPage =
		(article || service) &&
		(await content.query({
			getArticlesPage: [
				{ by: { unique: One.One } },
				{
					localesByLocale: [
						{ by: { locale: { code: urlInfo.locale } } },
						{ link: [{}, { url: true }] },
					],
				},
			],
		}))

	const articlesList =
		homePage &&
		(await content.query({
			listArticle: [
				{
					orderBy: [{ publishDate: OrderDirection.desc }],
					limit: 6,
					filter: { publishDate: { lte: 'now' } },
				},
				ArticleTileFragment(urlInfo.locale),
			],
		}))

	if (
		!homePage &&
		!contactPage &&
		!realization &&
		!realizationsPage &&
		!articlesPage &&
		!article &&
		!service
	) {
		context.res.statusCode = 404
	}

	return {
		props: {
			is404,
			url,
			urlInfo,
			dataFromContember,
			serverTime: new Date().toString(),
			homePage: homePage ?? null,
			contactPage: contactPage ?? null,
			realization: realization ?? null,
			realizationsPage: realizationsPage ?? null,
			realizationsPageExtras: realizationsPageExtras ?? null,
			allRealiazationsButtonDetailPage: allRealiazationsButtonDetailPage ?? null,
			article: article ?? null,
			articlesPage: articlesPage ?? null,
			articlesPageExtras: articlesPageExtras ?? null,
			allArticlesButtonDetailPage: allArticlesButtonDetailPage ?? null,
			service: service ?? null,
			recommendedArticles: recommendedArticles ?? null,
			articlesList: articlesList ?? null,
		},
	}
})
