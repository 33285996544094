import type { PartnersSectionResult } from '../app/data/content/PartnersSectionFragment'
import styles from '../styles/PartnersSection.module.sass'
import { ContemberImage } from './ContemberImage'

export const PartnersSection = (props: { partners: PartnersSectionResult }) => {
	const { partners } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.title}>{partners.title}</div>
			<div className={styles.logos}>
				{partners.logos?.items.map((logo) => {
					return (
						<div key={logo.id} className={styles.logo}>
							{logo.image && (
								<ContemberImage image={logo.image} layout="fill" objectFit="contain" />
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}
