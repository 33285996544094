import type { RealizationsSectionResult } from '../app/data/content/RealizationsSectionFragment'
import { filterNonEmpty } from '../app/utils/filterNonEmpty'
import styles from '../styles/RealizationsSection.module.sass'
import { Button } from './Button'
import { RealizationTilesList } from './RealizationTilesList'

export const RealizationsSection = (props: { realizations: RealizationsSectionResult }) => {
	const { realizations } = props

	return (
		<div>
			{realizations.subTitle && <div className={styles.subTitle}>{realizations.subTitle}</div>}
			<div className={styles.realizations}>
				{realizations.title && <div className={styles.title}>{realizations.title}</div>}
				{/* <div className={styles.tilesList}>
					{realizations.realizations.map((realizationItem) => {
						return (
							<div key={realizationItem.id} className={styles.tile}>
								{realizationItem.realization && realizationItem.realization.localesByLocale && (
									<RealizationTile realizationTile={realizationItem.realization?.localesByLocale} />
								)}
							</div>
						)
					})}
				</div> */}
				<RealizationTilesList
					realizationTilesList={realizations.realizations
						.map((realizationTile) => realizationTile.realization?.localesByLocale)
						.filter(filterNonEmpty)}
				/>
				{realizations.buttonLink && (
					<div className={styles.buttonWrapper}>
						<Button link={realizations.buttonLink} />
					</div>
				)}
			</div>
		</div>
	)
}
