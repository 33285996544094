import Head from 'next/head'
import { Container } from '../../components/Container'
import { RealizationTile } from '../../components/RealizationTile'
import type { CommonPageProps } from '../../pages/[[...page]]'
import styles from '../../styles/RealizationsPage.module.sass'
import type { RealizationTileResult } from '../data/content/RealizationTileFragment'

type RealizationsPageProps = PickRequiredValues<CommonPageProps, 'realizationsPage'> & {
	realizations?: RealizationTileResult[]
}

export function RealizationsPage(props: RealizationsPageProps) {
	const { realizationsPage, realizations } = props
	return (
		<div>
			<Head>
				<title>{realizationsPage.seo?.title}</title>
				<meta name="description" content={realizationsPage.seo?.description} />
				<meta property="og:title" content={realizationsPage.seo?.ogTitle} />
				<meta property="og:description" content={realizationsPage.seo?.ogDescription} />
				<meta property="og:image" content={realizationsPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				<div className={styles.title}>{realizationsPage.title}</div>
				<div className={styles.realizationsList}>
					{realizations?.map((item) => {
						return (
							<div key={item.id}>
								<RealizationTile realizationTile={item} />
							</div>
						)
					})}
				</div>
			</Container>
		</div>
	)
}
