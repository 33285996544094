import type { HeroResult } from '../app/data/content/HeroFragment'
import styles from '../styles/Hero.module.sass'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'

export const Hero = (props: { hero: HeroResult }) => {
	const { hero } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.background}>
				{hero.image && (
					<div className={styles.backgroundIn}>
						<ContemberImage image={hero.image} layout="fill" objectFit="cover" />
					</div>
				)}
			</div>
			<div className={styles.mobileBackground}>
				{hero.mobileImage && (
					<div className={styles.mobileBackgroundIn}>
						<ContemberImage image={hero.mobileImage} layout="fill" objectFit="cover" />
					</div>
				)}
			</div>
			<div className={styles.contentWrapper}>
				<div className={styles.heroContainer}>
					<Container>
						<div className={styles.logoWrapper}>
							<div className={styles.logo}>
								{hero.logo && (
									<ContemberImage image={hero.logo} layout="fill" objectFit="contain" />
								)}
							</div>
						</div>
					</Container>
					<Container>
						<div className={styles.title}>{hero.title}</div>
						<div className={styles.labelsList}>
							{hero.labels?.items.map((label) => {
								return (
									<div key={label.id} className={styles.label}>
										<div className={styles.labelIconWrapper}>
											<div className={styles.labelIcon}>
												{label.icon && (
													<ContemberImage image={label.icon} layout="fill" objectFit="contain" />
												)}
											</div>
										</div>
										<div>
											<div className={styles.labelTitle}>{label.title}</div>
											<div className={styles.labelDescription}>{label.description}</div>
										</div>
									</div>
								)
							})}
						</div>
					</Container>
				</div>
			</div>
		</div>
	)
}
