import Link from 'next/link'
import React from 'react'
import type { LinkResult } from '../app/data/content/LinkFragment'
import { LinkType } from '../generated/content'

export const ContemberLink = React.memo(function ContemberLink(props: {
	link: LinkResult | string | undefined | null
	children?: React.ReactNode
	className?: string
}) {
	const { href, ...attrs } = React.useMemo(() => {
		if (typeof props.link === 'string') {
			return { href: props.link }
		}
		const { type, internalLink, externalLink, file } = props.link ?? {}
		const attrs: Partial<
			React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
		> = {}

		switch (type) {
			case LinkType.internal:
				return { ...attrs, href: internalLink?.url ?? '#' }
			case LinkType.external:
				return { ...attrs, href: externalLink ?? '#', target: '_blank', rel: 'noopener' }
			default:
				return { ...attrs, href: externalLink ?? '#' }
			case LinkType.file:
				return {
					...attrs,
					href: file?.url ?? '#',
					download: true,
					target: '_blank',
					rel: 'noopener',
				}
		}
	}, [props.link])

	if (!props.link || href === null) {
		return null
	}

	const content =
		typeof props.link === 'string' ? props.children : props.children ?? props.link.title

	return (
		<Link href={href}>
			<a {...attrs} className={props.className}>
				{content}
			</a>
		</Link>
	)
})
