/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx'
import { useState } from 'react'
import type { MemberResult } from '../app/data/content/MemberFragment'
import styles from '../styles/Member.module.sass'
import { ContemberImage } from './ContemberImage'
import { MemberModal } from './MemberModal'

export const Member = (props: { member: MemberResult }) => {
	const [isOpen, setIsOpen] = useState(false)
	const { member } = props

	return (
		<>
			<div className={styles.wrapper} role="button" onClick={() => setIsOpen(true)}>
				<div className={styles.memberImg}>
					{member.image && <ContemberImage image={member.image} layout="fill" objectFit="cover" />}
				</div>
				<div className={styles.textContent}>
					<div className={styles.name}>{member.name}</div>
					<div className={styles.description}>{member.description}</div>
				</div>
				<div className={styles.hoverDiv}>
					<div className={styles.hoverName}>{member.name}</div>
					<div className={styles.text}>{member.text}</div>
				</div>
			</div>
			<div className={clsx(styles.memberModal, isOpen && styles.isOpen)}>
				<div className={styles.modalContent}>
					{member.name && member.text && (
						<MemberModal name={member.name} text={member.text} onClose={() => setIsOpen(false)} />
					)}
				</div>
				<div
					className={styles.modalBackground}
					role="button"
					onClick={() => setIsOpen(false)}></div>
			</div>
		</>
	)
}
