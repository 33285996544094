import Head from 'next/head'
import { ArticlesSection } from '../../components/ArticlesSection'
import { Banner } from '../../components/Banner'
import { Container } from '../../components/Container'
import { Hero } from '../../components/Hero'
import { Mantras } from '../../components/Mantras'
import { MembersSection } from '../../components/MembersSection'
import { PartnersSection } from '../../components/PartnersSection'
import { RealizationsSection } from '../../components/RealizationsSection'
import { ServicesSection } from '../../components/ServicesSection'
import type { CommonPageProps } from '../../pages/[[...page]]'
import type { ArticleTileResult } from '../data/content/ArticleTileFragment'

type HomePageProps = PickRequiredValues<CommonPageProps, 'homePage'> & {
	articles?: ArticleTileResult[]
}

export function HomePage(props: HomePageProps) {
	const {
		hero,
		realizationsSection,
		membersSection,
		partnersSection,
		banner,
		mantras,
		servicesSection,
		articlesSection,
		root,
	} = props.homePage
	const { articles } = props
	return (
		<div>
			<Head>
				<title>Wero</title>
				<meta name="description" content={root?.seo?.description} />
				<meta property="og:title" content={root?.seo?.ogTitle} />
				<meta property="og:description" content={root?.seo?.ogDescription} />
				<meta property="og:image" content={root?.seo?.ogImage?.url} />
			</Head>

			{hero && <Hero hero={hero} />}

			<Container>{servicesSection && <ServicesSection services={servicesSection} />}</Container>

			<Container>{mantras && <Mantras mantras={mantras} />}</Container>
			<Container>
				{realizationsSection && <RealizationsSection realizations={realizationsSection} />}
			</Container>
			<Container>
				{articlesSection && (
					<ArticlesSection articleSection={articlesSection} articles={articles} />
				)}
			</Container>
			<Container contentWidth="narrow">
				{membersSection && <MembersSection members={membersSection} />}
			</Container>
			<Container>{partnersSection && <PartnersSection partners={partnersSection} />}</Container>
			{banner && <Banner banner={banner} />}
		</div>
	)
}
