import type React from 'react'
import type { SiteResult } from '../app/data/content/SiteFragment'
import styles from '../styles/Idea.module.sass'
import { Button } from './Button'

export const Idea = (props: { idea: SiteResult['getIdea']; isSpecialServiceType?: boolean }) => {
	const { idea, isSpecialServiceType } = props

	return (
		<div
			className={styles.wrapper}
			style={
				isSpecialServiceType
					? ({ '--serviceType-color': ' #261E52' } as React.CSSProperties)
					: undefined
			}>
			<div className={styles.title}>{idea?.localesByLocale?.title}</div>
			<div className={styles.textWrapper}>
				<div className={styles.text}>{idea?.localesByLocale?.text}</div>
			</div>
			<div className={styles.button}>
				<Button
					link={idea?.localesByLocale?.buttonLink}
					color={isSpecialServiceType ? '#261E52' : '#AB9574'}
				/>
			</div>
		</div>
	)
}
