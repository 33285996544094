import Head from 'next/head'
import styles from '../../styles/Error404Page.module.sass'
import type { SiteResult } from '../data/content/SiteFragment'

type Error404PageProps = {
	error404Page: SiteResult['getError404Page']
}

export function Error404Page(props: Error404PageProps) {
	const { error404Page } = props
	return (
		<div>
			<Head>
				<title>{error404Page?.localesByLocale?.title}</title>
			</Head>
			<h1 className={styles.title}>{error404Page?.localesByLocale?.title}</h1>
		</div>
	)
}
