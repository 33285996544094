import Image from 'next/image'
import Link from 'next/link'
import type { RealizationTileResult } from '../app/data/content/RealizationTileFragment'
import arrow from '../public/arrowWhite.svg'
import playButton from '../public/playButton.svg'
import styles from '../styles/RealizationTile.module.sass'
import { ContemberImage } from './ContemberImage'

export const RealizationTile = (props: { realizationTile: RealizationTileResult }) => {
	const { realizationTile } = props

	return (
		<div className={styles.wrapper}>
			<Link href={realizationTile.link?.url || '/'}>
				<a>
					{realizationTile.media?.items[0].video?.youtube &&
						!realizationTile.media?.items[0].video?.poster && (
							<div className={styles.ytPoster}>
								<Image
									alt=""
									src={`https://img.youtube.com/vi/${realizationTile.media?.items[0].video?.youtube?.videoId}/default.jpg`}
									layout="fill"
									objectFit="cover"
								/>
								<div className={styles.playButton}>
									<Image src={playButton} alt="" />
								</div>
							</div>
						)}
					{realizationTile.media?.items[0].video?.poster && (
						<div className={styles.videoPoster}>
							<ContemberImage
								image={realizationTile.media?.items[0].video?.poster}
								layout="fill"
								objectFit="cover"
							/>
							<div className={styles.playButton}>
								<Image src={playButton} alt="" />
							</div>
						</div>
					)}
					{realizationTile.media?.items[0].image &&
						!realizationTile.media?.items[0].video?.poster && (
							<div className={styles.image}>
								<ContemberImage
									image={realizationTile.media?.items[0].image}
									layout="fill"
									objectFit="cover"
								/>
							</div>
						)}

					<div className={styles.title}>{realizationTile.title}</div>
					<div className={styles.labelsWrapper}>
						<div className={styles.arrow}>
							<Image src={arrow} alt="" />
						</div>
						<div className={styles.tileTextWrapper}>
							<div className={styles.tileTitle}>{realizationTile.title}</div>
							{realizationTile.tileLabelList?.items &&
								realizationTile.tileLabelList?.items.length > 0 && (
									<ul className={styles.labelsList}>
										{realizationTile.tileLabelList?.items.map((label) => {
											return (
												<li className={styles.labelTitle} key={label.id}>
													{label.title}
												</li>
											)
										})}
									</ul>
								)}
						</div>
					</div>
				</a>
			</Link>
		</div>
	)
}
