import { RichTextRenderer } from '@contember/react-client'
import Head from 'next/head'
import { Container } from '../../components/Container'
import styles from '../../styles/ContactPage.module.sass'
import type { ContactPageLocaleResult } from '../data/content/ContactPageLocaleFragment'
import { ContactForm } from '../forms/contactForm/ContactForm'

export function ContactPage(props: { contactPage: ContactPageLocaleResult }) {
	const { contactPage } = props

	return (
		<div>
			<Head>
				<title>{contactPage.seo?.title}</title>
				<meta name="description" content={contactPage.seo?.description} />
				<meta property="og:title" content={contactPage.seo?.ogTitle} />
				<meta property="og:description" content={contactPage.seo?.ogDescription} />
				<meta property="og:image" content={contactPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				<div className={styles.wrapper}>
					<div className={styles.title}>{contactPage.title}</div>
					<div>
						<div>
							<a href={`mailto: ${contactPage.email}`} className={styles.email}>
								{contactPage.email}
							</a>
						</div>
						<div>
							<a href={`tel: ${contactPage.tel}`} className={styles.tel}>
								{contactPage.tel}
							</a>
						</div>
						<div className={styles.IC}>{contactPage.IC}</div>

						{contactPage.address && (
							<div className={styles.address}>
								<RichTextRenderer source={contactPage.address} />
							</div>
						)}
					</div>
				</div>
				<div>{contactPage.form && <ContactForm contactForm={contactPage.form} />}</div>
			</Container>
		</div>
	)
}
