import type { ServicesSectionResult } from '../app/data/content/ServicesSectionFragment'
import styles from '../styles/ServicesSection.module.sass'
import { ServiceTile } from './ServiceTile'

export const ServicesSection = (props: { services: ServicesSectionResult }) => {
	const { services } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.textContent}>
				<div className={styles.title}>{services.title}</div>
				<div className={styles.text}>{services.text}</div>
			</div>
			<div className={styles.tilesList}>
				{services.services.map((serviceItem) => {
					return (
						<div key={serviceItem.id}>
							{serviceItem.service?.localesByLocale && (
								<ServiceTile serviceTile={serviceItem.service?.localesByLocale} />
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}
