import styles from '../styles/Arrow.module.sass'

export const Arrow = () => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.arrowWrapper}>
				<div className={styles.arrow}></div>
			</div>
		</div>
	)
}
