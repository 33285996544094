import * as z from 'zod'

export const ContactFormInput = () =>
	z.object({
		name: z.string().nonempty(),
		tel: z.string().nonempty(),
		email: z.string().nonempty(),
		note: z.string(),
	})

export type ContactFormInputData = z.infer<ReturnType<typeof ContactFormInput>>
