import { RichTextRenderer } from '@contember/react-client'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import type { SiteResult } from '../app/data/content/SiteFragment'
import styles from '../styles/CookieBar.module.sass'
import { Checkbox } from './Checkbox'
import { Container } from './Container'

function consent(options: {
	technicalCookie: boolean
	analyticalCookie?: boolean
	marketingCookie?: boolean
}) {
	const data = {
		date: new Date().toISOString(),
		options,
	}
	window.localStorage.setItem('cookieBarConsent', JSON.stringify(data))
}

export const CookieBar = (props: { cookieBar?: SiteResult['getCookieBar'] }) => {
	const { cookieBar } = props
	const [isClosed, setIsClosed] = useState(false)
	const [showToggle, setShowToggle] = useState(false)

	useEffect(() => {
		const isCookieBarClosed = window.localStorage.getItem('cookieBarClosed')
		if (isCookieBarClosed) {
			setIsClosed(true)
		}
	}, [])

	return (
		<div className={clsx(styles.wrapper, isClosed && styles.isClosed)}>
			<Container>
				<div className={styles.titleWrapper}>
					<div>{cookieBar?.localesByLocale?.title}</div>
					<button
						className={styles.crossButton}
						onClick={() => {
							setIsClosed(true)
							window.localStorage.setItem('cookieBarClosed', 'close-button')
							consent({ technicalCookie: true })
						}}>
						<div className={styles.line1} />
						<div className={styles.line2} />
					</button>
				</div>
				{cookieBar?.localesByLocale?.text && (
					<div className={styles.text}>
						<RichTextRenderer source={cookieBar?.localesByLocale?.text} />
					</div>
				)}
				<form
					className={clsx(styles.toggleWrapper, showToggle && styles.showToggle)}
					method="post"
					onSubmit={(e) => {
						e.preventDefault()
						const formData = new FormData(e.currentTarget)
						setIsClosed(true)
						consent({
							technicalCookie: true,
							analyticalCookie: Boolean(formData.get('analyticalCookie')),
							marketingCookie: Boolean(formData.get('marketingCookie')),
						})
					}}>
					<div className={styles.inputItems}>
						{cookieBar?.localesByLocale?.technicalCookie && (
							<Checkbox
								label={cookieBar?.localesByLocale?.technicalCookie}
								name="technicalCookie"
								disabled
								checked
							/>
						)}
						{cookieBar?.localesByLocale?.analyticalCookie && (
							<Checkbox
								label={cookieBar?.localesByLocale?.analyticalCookie}
								name="analyticalCookie"
							/>
						)}
						{cookieBar?.localesByLocale?.marketingCookie && (
							<Checkbox
								label={cookieBar?.localesByLocale?.marketingCookie}
								name="marketingCookie"
							/>
						)}
					</div>
					<div className={styles.detailButtonsWrapper}>
						<button className={styles.acceptSelectedButton} type="submit">
							{cookieBar?.localesByLocale?.acceptSelectedButton}
						</button>
						<button
							className={styles.backButton}
							onClick={() => setShowToggle(!showToggle)}
							type="button">
							{cookieBar?.localesByLocale?.backButton}
						</button>
					</div>
				</form>
				<div className={clsx(styles.buttonsWrapper, showToggle && styles.hideButtons)}>
					<button
						className={styles.moreButton}
						onClick={() => {
							setShowToggle(!showToggle)
						}}>
						{cookieBar?.localesByLocale?.moreButton}
					</button>
					<button
						className={styles.consentButton}
						onClick={() => {
							setIsClosed(true)
							window.localStorage.setItem('cookieBarClosed', 'default-button')
							consent({ technicalCookie: true, analyticalCookie: true, marketingCookie: true })
						}}>
						{cookieBar?.localesByLocale?.consentButton}
					</button>
				</div>
			</Container>
		</div>
	)
}
