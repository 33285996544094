import { useMemo } from 'react'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import { useTranslate } from '../app/hooks/useTranslate'
import styles from '../styles/RecommendedArticles.module.sass'
import { ArticleTile } from './ArticleTile'
import { Button } from './Button'
import { Container } from './Container'

export const RecommededArticles = (props: {
	recommendedArticles: ArticleTileResult[]
	extraProps?: { getArticlesPage?: { localesByLocale?: { link?: { url: string } } } } | null
}) => {
	const { recommendedArticles, extraProps } = props

	const mainArticles = useMemo(() => recommendedArticles.slice(0, 2), [recommendedArticles])
	const otherArticles = useMemo(() => recommendedArticles.slice(2), [recommendedArticles])
	const translate = useTranslate()
	return (
		<Container>
			{recommendedArticles.length > 0 && (
				<div>
					<div className={styles.title}>{translate('recommendedArticles.title')}</div>
					<div className={styles.articles}>
						<div className={styles.mainArticlesList}>
							{mainArticles?.map((item, index) => {
								return (
									<div key={item.id}>
										<ArticleTile
											articleTile={item}
											showImage={true}
											view={index === 1 ? 'hideImageOnMobile' : undefined}
										/>
									</div>
								)
							})}
						</div>
						<div className={styles.otherArticlesSection}>
							<div>
								{otherArticles?.map((item) => {
									return (
										<div key={item.id} className={styles.otherArticle}>
											<ArticleTile articleTile={item} showImage={false} />
										</div>
									)
								})}
							</div>
							{extraProps?.getArticlesPage?.localesByLocale?.link?.url && (
								<Button
									title={translate('recommendedArticles.button')}
									linkUrl={extraProps?.getArticlesPage?.localesByLocale?.link?.url}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</Container>
	)
}
