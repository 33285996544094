import type { MantrasResult } from '../app/data/content/MantrasFragment'
import styles from '../styles/Mantras.module.sass'
import { ContemberImage } from './ContemberImage'

export const Mantras = (props: { mantras: MantrasResult }) => {
	const { mantras } = props
	return (
		<div className={styles.wrapper}>
			<div>
				<div className={styles.subtitle}>{mantras.subTitle}</div>
				<div className={styles.title}>{mantras.title}</div>
				<div className={styles.mantrasList}>
					{mantras.mantras?.items.map((mantra) => {
						return (
							<div key={mantra.id} className={styles.mantra}>
								<div className={styles.mantraContent}>
									<div className={styles.iconWrapper}>
										<div className={styles.icon}>
											{mantra.icon && (
												<ContemberImage image={mantra.icon} layout="fill" objectFit="contain" />
											)}
										</div>
									</div>
									<div className={styles.mantraTitle}>{mantra.title}</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
