import type { MembersSectionResult } from '../app/data/content/MembersSectionFragment'
import styles from '../styles/MembersSection.module.sass'
import { Member } from './Member'

export const MembersSection = (props: { members: MembersSectionResult }) => {
	const { members } = props
	return (
		<div>
			<div className={styles.subTitle}>{members.subTitle}</div>
			<div className={styles.title}>
				<div className={styles.titleIn}>{members.title}</div>
			</div>
			<div className={styles.membersList}>
				{members.members.map((member) => {
					return (
						<div key={member.id} className={styles.member}>
							{member && <Member member={member} />}
						</div>
					)
				})}
			</div>
		</div>
	)
}
