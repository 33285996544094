/* eslint-disable jsx-a11y/media-has-caption */
import Head from 'next/head'
import { BackButton } from '../../components/BackButton'
import { Container } from '../../components/Container'
import { ContemberImage } from '../../components/ContemberImage'
import { MediaList } from '../../components/MediaList'
import styles from '../../styles/RealizationDetailPage.module.sass'
import type { RealizationLocaleResult } from '../data/content/RealizationLocaleFragment'
import { useTranslate } from '../hooks/useTranslate'

export function RealiazationDetailPage(props: {
	realizationDetailPage: RealizationLocaleResult
	extraProps?: { getRealizationsPage?: { localesByLocale?: { link?: { url: string } } } } | null
}) {
	const { realizationDetailPage, extraProps } = props
	const translate = useTranslate()

	return (
		<div>
			<Head>
				<title>{realizationDetailPage.title}</title>
				<meta name="description" content={realizationDetailPage.seo?.description} />
				<meta property="og:title" content={realizationDetailPage.seo?.ogTitle} />
				<meta property="og:description" content={realizationDetailPage.seo?.ogDescription} />
				<meta property="og:image" content={realizationDetailPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				<div className={styles.content}>
					<div className={styles.textWrapper}>
						<div className={styles.button}>
							{extraProps?.getRealizationsPage?.localesByLocale?.link?.url && (
								<BackButton
									title={translate('realizationDetail.back')}
									link={extraProps?.getRealizationsPage?.localesByLocale?.link?.url}
									isDetailPage={true}
								/>
							)}
						</div>

						<div className={styles.title}>{realizationDetailPage.title}</div>
						<div className={styles.labelsList}>
							{realizationDetailPage.labelList?.items.map((label, index) => {
								return (
									<div key={label.id} className={styles.labelWrapper}>
										{label.icon && (
											<div className={styles.iconWrapper}>
												<div className={styles.icon}>
													<ContemberImage image={label.icon} layout="fill" objectFit="contain" />
												</div>
											</div>
										)}
										<div className={styles.labelTitle}>
											{!label.icon && index + 1 + '.'} {label.title}
										</div>
									</div>
								)
							})}
						</div>
					</div>
					<div className={styles.media}>
						{realizationDetailPage.media && <MediaList mediaList={realizationDetailPage.media} />}
					</div>
				</div>
			</Container>
		</div>
	)
}
