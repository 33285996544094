import styles from '../styles/Input.module.sass'

export const Input = (props: {
	name: string
	type: string
	placeholder?: string
	autoComplete?: string
	required?: boolean
}) => {
	const { name, type, placeholder, autoComplete, required = false } = props
	return (
		<>
			<input
				className={styles.input}
				type={type}
				name={name}
				placeholder={placeholder}
				autoComplete={autoComplete}
				required={required}
			/>
		</>
	)
}
