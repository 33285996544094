import clsx from 'clsx'
import Link from 'next/link'
import styles from '../styles/BackButton.module.sass'
import { Arrow } from './Arrow'

export const BackButton = (props: { title: string; link: string; isDetailPage?: boolean }) => {
	const { title, link, isDetailPage } = props
	return (
		<div>
			<div className={clsx(styles.buttonWrapper, isDetailPage && styles.isDetailPage)}>
				<div className={styles.button}>
					<div className={styles.arrow}>
						<Arrow />
					</div>

					<Link href={link}>
						<a>{title}</a>
					</Link>
				</div>
			</div>
		</div>
	)
}
