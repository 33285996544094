import clsx from 'clsx'
import type { ArticlesSectionResult } from '../app/data/content/ArticlesSectionFragment'
import type { ArticleTileResult } from '../app/data/content/ArticleTileFragment'
import styles from '../styles/ArticlesSection.module.sass'
import { ArticleTile } from './ArticleTile'
import { Button } from './Button'

export const ArticlesSection = (props: {
	articleSection: ArticlesSectionResult
	articles?: ArticleTileResult[]
}) => {
	const { articleSection, articles } = props
	return (
		<div className={styles.wrapper}>
			<div className={styles.contentWrapper}>
				<div className={styles.textWrapper}>
					<div className={styles.subTitle}>{articleSection.subtitle}</div>
					<div className={styles.title}>{articleSection.title}</div>
				</div>
				{articles?.map((article, index) => {
					return (
						<div
							key={article.id}
							className={clsx(
								styles.article,
								index > 1 && (index % 2 == 1 ? styles.notMainEven : styles.notMainOdd)
							)}>
							<ArticleTile
								articleTile={article}
								showImage={index < 2}
								view={index === 1 ? 'hideImageOnMobile' : undefined}
							/>
						</div>
					)
				})}
				<div className={styles.buttonWrapper}>
					{articleSection.buttonLink && <Button link={articleSection.buttonLink} />}
				</div>
			</div>
		</div>
	)
}
