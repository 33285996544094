import styles from '../styles/Checkbox.module.sass'

export const Checkbox = (props: {
	label: string
	disabled?: boolean
	name: string
	checked?: boolean
}) => {
	const { label, disabled, name, checked } = props
	return (
		<label className={styles.checkbox}>
			<input
				type="checkbox"
				disabled={disabled}
				name={name}
				className={styles.input}
				checked={checked}
			/>
			<div className={styles.label}>
				{label}
				<span className={styles.tick}></span>
			</div>
		</label>
	)
}
