import { z, ZodError } from 'zod'
import { ValidationError, zodErrorInfo } from '../../../packages/@necktip/next/api/jsonApiHandler'

export function validateZod<Validated>(
	data: unknown | FormData,
	validator: { parse: (data: unknown, options?: Partial<z.ParseParams>) => Validated },
	options?: Partial<z.ParseParams>
) {
	const input: unknown = data instanceof FormData ? Object.fromEntries(data.entries()) : data

	try {
		return validator.parse(input, { ...(options ?? {}) })
	} catch (e) {
		if (e instanceof ZodError) {
			throw new ValidationError(
				{ status: 'error', validationErrors: zodErrorInfo(e) },
				process.browser ? 'browser' : 'server'
			)
		} else {
			throw e
		}
	}
}
