import { RichTextRenderer } from '@contember/react-client'
import type React from 'react'
import type { DescriptionsListResult } from '../app/data/content/DescriptionListFragment'
import type { ImageListResult } from '../app/data/content/ImageListFragment'
import styles from '../styles/DescriptionsSection.module.sass'
import { ContemberImage } from './ContemberImage'

export const DescriptionsSection = (props: {
	title: string | undefined
	descriptions: DescriptionsListResult
	images?: ImageListResult
}) => {
	const { title, descriptions, images } = props
	return (
		<div>
			<div className={styles.title}>{title}</div>

			<div className={styles.wrapper}>
				<div className={styles.descriptionWrapper}>
					{descriptions?.items.map((item) => {
						return (
							<div key={item.id} className={styles.description}>
								<div className={styles.descriptionTitle}>{item.title}</div>
								<div className={styles.text}>
									{item.text && <RichTextRenderer source={item.text} />}
								</div>
							</div>
						)
					})}
				</div>

				<div className={styles.imageList}>
					{images?.items.map((image) => {
						return (
							<div key={image.id}>
								{image.image && (
									<div className={styles.image}>
										<ContemberImage image={image.image} layout="fill" objectFit="cover" />
									</div>
								)}
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
