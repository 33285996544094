import clsx from 'clsx'
import React from 'react'
import styles from '../styles/Container.module.sass'

type ContainerProps = {
	children: React.ReactNode
	contentWidth?: 'narrow' | 'wide'
	disableGutters?: boolean
}

export const Container = (props: ContainerProps) => {
	const { children, contentWidth, disableGutters } = props
	return (
		<div
			className={clsx(
				styles.wrapper,
				styles[`view-${contentWidth}`],
				disableGutters && styles['view-disableGutters']
			)}>
			{children}
		</div>
	)
}
