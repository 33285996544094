import { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/controller'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Swiper, SwiperSlide } from 'swiper/react'
import type { ImageListResult } from '../app/data/content/ImageListFragment'
import styles from '../styles/ImageCarousel.module.sass'
import { Arrow } from './Arrow'
import { ContemberImage } from './ContemberImage'

export const ImageCarousel = (props: { images: ImageListResult }) => {
	const { images } = props

	return (
		<Swiper
			className={styles.wrapper}
			spaceBetween={0}
			slidesPerView={1}
			loop
			navigation={{ nextEl: `.${styles.rightArrow}`, prevEl: `.${styles.leftArrow}` }}
			modules={[Navigation]}
			onSwiper={(swiper) => console.log(swiper)}
			onSlideChange={() => console.log('slide change')}>
			{images?.items.map((image, index) => {
				return (
					<SwiperSlide key={image.id}>
						{image.image && (
							<div className={styles.imageWrapper}>
								<div className={styles.image}>
									<ContemberImage image={image.image} layout="fill" objectFit="cover" />
								</div>
								<div className={styles.text}>
									<div>{`${index + 1}/${images.items.length}`}</div>
									<div className={styles.description}>{image.description}</div>
								</div>
							</div>
						)}
					</SwiperSlide>
				)
			})}
			<div className={styles.arrows}>
				<button className={styles.leftArrow}>
					<Arrow />
				</button>
				<button className={styles.rightArrow}>
					<Arrow />
				</button>
			</div>
		</Swiper>
	)
}
