import Image from 'next/image'
import React from 'react'
import type { ImageResult } from '../app/data/content/ImageFragment'

type ImageProps = React.ComponentProps<typeof Image>

export function ContemberImage(props: {
	layout?: ImageProps['layout']
	objectFit?: ImageProps['objectFit']
	objectPosition?: ImageProps['objectPosition']
	onLoad?: ImageProps['onLoad']
	sizes?: string
	image: ImageResult
}) {
	const {
		image: { url, width, height },
	} = props
	if (!(url && width && height)) {
		return null
	}

	const baseProps = {
		sizes: props.sizes,
	}

	if (props.layout === 'fill') {
		return (
			<Image
				{...baseProps}
				src={url}
				alt={props.image.alt ?? ''}
				layout={props.layout}
				objectFit={props.objectFit}
				objectPosition={props.objectPosition}
				onLoad={props.onLoad}
			/>
		)
	}
	return (
		<Image
			{...baseProps}
			src={url}
			width={width}
			height={height}
			alt={props.image.alt ?? ''}
			layout={props.layout}
			onLoad={props.onLoad}
		/>
	)
}
