import Head from 'next/head'
import { useMemo } from 'react'
import { ArticleTile } from '../../components/ArticleTile'
import { Container } from '../../components/Container'
import type { CommonPageProps } from '../../pages/[[...page]]'
import styles from '../../styles/ArticlesPage.module.sass'
import type { ArticleTileResult } from '../data/content/ArticleTileFragment'

type ArticlesPageProps = PickRequiredValues<CommonPageProps, 'articlesPage'> & {
	articles?: ArticleTileResult[]
}

export function ArticlesPage(props: ArticlesPageProps) {
	const { articlesPage, articles = [] } = props

	const mainArticles = useMemo(() => articles.slice(0, 4), [articles])
	const otherArticles = useMemo(() => articles.slice(4), [articles])

	return (
		<div>
			<Head>
				<title>{articlesPage.seo?.title}</title>
				<meta name="description" content={articlesPage.seo?.description} />
				<meta property="og:title" content={articlesPage.seo?.ogTitle} />
				<meta property="og:description" content={articlesPage.seo?.ogDescription} />
				<meta property="og:image" content={articlesPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				<div className={styles.title}>{articlesPage.title}</div>
				<div className={styles.articles}>
					<div className={styles.mainArticlesList}>
						{mainArticles?.map((item) => {
							return (
								<div key={item.id}>
									<ArticleTile articleTile={item} showImage={true} />
								</div>
							)
						})}
					</div>
					<div className={styles.otherArticlesSection}>
						{otherArticles?.map((item) => {
							return (
								<div key={item.id} className={styles.otherArticle}>
									<ArticleTile articleTile={item} showImage={false} />
								</div>
							)
						})}
					</div>
				</div>
			</Container>
		</div>
	)
}
