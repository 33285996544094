/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link'
import type { SiteResult } from '../app/data/content/SiteFragment'
import { isSpecialKeyPressed } from '../app/utils/isSpecialKeyPressed'
import styles from '../styles/Navigation.module.sass'
import { ArrowLink } from './ArrowLink'
import { Container } from './Container'
import { ContemberImage } from './ContemberImage'
import { ContemberLink } from './ContemberLink'
import { LocaleSwitcher } from './LocaleSwitcher'

type NavigationProps = {
	navigation: SiteResult['getHeader']
	services: SiteResult['listService']
	activeLocaleCode?: string
	pageLocales?: {
		id: string
		link?: {
			url: string
		}
		locale?: {
			code: string
		}
	}[]
	onClose: () => void
}
export const Navigation = (props: NavigationProps) => {
	const { navigation, services, pageLocales, activeLocaleCode, onClose } = props
	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div
			className={styles.navigation}
			onClick={(event) => {
				if (
					!isSpecialKeyPressed(event.nativeEvent) &&
					event.target instanceof HTMLElement &&
					event.target.closest('a') !== null
				) {
					onClose()
				}
			}}>
			<Container>
				<div className={styles.contentWrapper}>
					<Link href="/">
						<a className={styles.logoPosition}>
							<div className={styles.logoWrapper}>
								<div className={styles.logo}>
									{navigation?.localesByLocale?.invertedLogo && (
										<ContemberImage
											image={navigation?.localesByLocale?.invertedLogo}
											layout="fill"
											objectFit="contain"
										/>
									)}
								</div>
							</div>
						</a>
					</Link>
					<Link href="/">
						<a className={styles.mobileLogoPosition}>
							<div className={styles.logoWrapper}>
								<div className={styles.logo}>
									{navigation?.localesByLocale?.invertedLogo && (
										<ContemberImage
											image={navigation?.localesByLocale?.invertedLogo}
											layout="fill"
											objectFit="contain"
										/>
									)}
								</div>
							</div>
						</a>
					</Link>
					<div className={styles.navigationLinks}>
						{navigation?.localesByLocale?.navigation?.items.map((item) => {
							return (
								<div key={item.id} className={styles.link}>
									<ContemberLink link={item.link} />
								</div>
							)
						})}
						<div className={styles.servicesList}>
							<div className={styles.serviceSectionTitle}>
								{navigation?.localesByLocale?.serviceSectionTitle}
							</div>
							{services.map((service) => {
								return (
									<div key={service.id}>
										{service.localesByLocale?.link?.url && (
											<Link href={service.localesByLocale?.link?.url}>
												<a>
													{service.localesByLocale.tileTitle && (
														<ArrowLink
															title={service.localesByLocale.tileTitle}
															serviceTitle
															color="#FFF"
														/>
													)}
												</a>
											</Link>
										)}
									</div>
								)
							})}
						</div>
					</div>
					<div className={styles.rightSection}>
						<LocaleSwitcher
							pageLocales={pageLocales}
							activeLocaleCode={activeLocaleCode}
							invertedHeader={true}
						/>
						<button className={styles.closeButtonWrapper} onClick={() => onClose()}>
							<div className={styles.closeButton}>
								<div className={styles.line1}></div>
								<div className={styles.line2}></div>
							</div>
							<div className={styles.closeButtonTitle}>
								{navigation?.localesByLocale?.closeButtonTitle}
							</div>
						</button>
					</div>
				</div>
			</Container>
		</div>
	)
}
