/* eslint-disable jsx-a11y/media-has-caption */
import { RichTextRenderer } from '@contember/react-client'
import Head from 'next/head'
import { BackButton } from '../../components/BackButton'
import { Container } from '../../components/Container'
import { MediaList } from '../../components/MediaList'
import styles from '../../styles/ArticleDetailPage.module.sass'
import type { ArticleLocaleResult } from '../data/content/ArticleLocaleFragment'
import { useTranslate } from '../hooks/useTranslate'

export function ArticleDetailpage(props: {
	articleDetailPage: ArticleLocaleResult
	extraProps?: { getArticlesPage?: { localesByLocale?: { link?: { url: string } } } } | null
}) {
	const { articleDetailPage, extraProps } = props
	const translate = useTranslate()
	const date = new Date(articleDetailPage.root?.publishDate)

	return (
		<div>
			<Head>
				<title>{articleDetailPage.seo?.title}</title>
				<meta name="description" content={articleDetailPage.seo?.description} />
				<meta property="og:title" content={articleDetailPage.seo?.ogTitle} />
				<meta property="og:description" content={articleDetailPage.seo?.ogDescription} />
				<meta property="og:image" content={articleDetailPage.seo?.ogImage?.url} />
			</Head>
			<Container>
				<div className={styles.wrapper}>
					{extraProps?.getArticlesPage?.localesByLocale?.link?.url && (
						<div className={styles.button}>
							<BackButton
								title={translate('articleDetail.back')}
								link={extraProps?.getArticlesPage?.localesByLocale?.link?.url}
								isDetailPage={true}
							/>
						</div>
					)}
					<div className={styles.contentWrapper}>
						<div className={styles.textContent}>
							<div className={styles.title}>{articleDetailPage.title}</div>
							<div className={styles.date}>{`${date.getDate()}. ${
								date.getMonth() + 1
							}. ${date.getFullYear()}`}</div>
							<div className={styles.text}>
								{articleDetailPage.text && <RichTextRenderer source={articleDetailPage.text} />}
							</div>
						</div>
						{articleDetailPage.media && <MediaList mediaList={articleDetailPage.media} />}
					</div>
				</div>
			</Container>
		</div>
	)
}
