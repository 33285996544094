import styles from '../styles/FlashMessage.module.sass'

type FlashMessageProps = {
	hideFlashMessage: () => void
}

export const FlashMessage = (props: FlashMessageProps) => {
	const { hideFlashMessage } = props
	return (
		<div className={styles.wrapper} onAnimationEnd={hideFlashMessage}>
			Form was successfully sent
		</div>
	)
}
